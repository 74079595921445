import React from 'react'
import { Link, navigate } from "gatsby";

import {
  Seo,
  Zoom,
} from '@components'
import { 
  OtaUpdateImage, 
  RightSidebar,
  LiveMetrics2Image, 
  LiveMetricsImage,
  RealTimeThreatIntelligenceBanner
} from '../../../components/site/product';
import { PageLayout } from '../../../components/site/layout/page-layout';

import { BenefitCard } from "../../../components/core/benefit-card";
import { RefreshUpdateIcon } from '../../../components/core/icons/refresh_update'
import { OperationalInfoIcon } from '../../../components/core/icons/operational_info'
import { ThreatAttackIcon } from '../../../components/core/icons/threat_attacks'

const AppProtectionPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <RealTimeThreatIntelligenceBanner title="Real Time Threat Intelligence"/>

      <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Real Time Threat Intelligence
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">
                    Real Time Threat Intelligence provides a live feed of information from running apps and the attacks they are facing in the field. This information is used to constantly evolve Approov's security models, with the flexibility to push out Over-The-Air updates as needed.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <BenefitCard className="mt-[20px]"
                Icon={
                  <RefreshUpdateIcon className="w-[55px] h-[55px] flex-shrink-0"/>
                }
                title="Instant Update"
                text="Update your security posture instantly with no need to release a new app version"
              />

              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[20px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <OperationalInfoIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Live Operational Information"
                  text="See live information from your installed base of apps"
                />
                <BenefitCard
                  className="md:w-1/2"
                  Icon={
                    <ThreatAttackIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Understand the Threats and Attacks"
                  text="Understand the threats and attacks your apps are experiencing"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Live Metrics</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Live metrics are accumulated regarding device usage, <Link href="https://approov.io/mobile-app-security/rasp/app-shielding/" className="">integrity failure reason forensics</Link>, and billing information. Information is presented in powerful <Link href="https://grafana.com/" className="">Grafana</Link> dashboards showing trends and allowing zooming into specific time periods and cross-correlation of events.
              </p>
              <Zoom>{LiveMetrics2Image}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Live Threats</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Previews are available of the type of information made available to our customers. This is based on delayed anonymized data from across our customer base. Individual customers are provided with live data for their own apps. See <Link href="https://approov.io/threats/real-time/" className="">Threats Detected</Link>, <Link href="https://approov.io/threats/category/" className="">Threats By Category</Link>, <Link href="https://approov.io/threats/android-category/" className="">Android Threats By Category</Link> and <Link href="https://approov.io/threats/ios-category/" className="">iOS Threats By Category</Link>.
              </p>
              <Zoom>{LiveMetricsImage}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Activity Reports</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Detailed summaries of daily or monthly activity across all of your app's user base are provided. This shows the usage by app name and app version, as well as detailed insights into the number of app instances that have been rejected by Approov and the detailed reasons for this.
              </p>
              
              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Continuous API Monitoring</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                If your app's backend API endpoints were to go down or otherwise become inaccessible then your app will suffer an outage. Our servers continuously monitor your app's backend API endpoints. If these become inaccessible or the certificate pins that they use are no longer valid then a notification is immediately sent. Using the <Link href="https://approov.io/threats/real-time/" className="">Dynamic Certificate Pinning</Link> feature of Approov pins can be updated immediately over the air with no need to update the app itself.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Over-the-Air Updates</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Attackers continuously evolve their runtime penetration techniques, and Approov stays up to date by providing security detection updates over-the-air without requiring app store updates. This live update service is also used to manage trust certificates and security policies.
              </p>
              <Zoom>{OtaUpdateImage}</Zoom>

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  )
}

export default AppProtectionPage
